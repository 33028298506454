body {
  height: 100vh;
  overflow: auto;
}
.logo {
  height: 32px;
  margin: 16px;
  color: white;
  font-weight: bolder;
  font-size: 30px;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}
  [data-theme="dark"] .site-layout-sub-header-background {
    background: #141414;
  }